<template>
  <div class="card col-md-12 p-3 mb-10" style="display:block;">
    <div style="display:inline-block;" class="col-md-6">
      <span class="px-8" style="font-weight: 600;color: #009EF7;">Semester</span>
      <el-select
          class="form-select-solid w-100 px-5"
          placeholder="Select semester"
          v-model="selectedSemester"
          @change="updateClasses"
      >
        <el-option 
          v-for="semester in semesters" 
          :label="semester.name+'('+semester.code+') : '+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')" 
          :value="semester.id"
          :key="semester">
          {{semester.name+'('+semester.code+') : '+moment(semester.start_date).format('YYYY.MM.DD')+'~'+moment(semester.end_date).format('YYYY.MM.DD')}}
        </el-option>
      </el-select>
    </div>
    <div style="display:inline-block;" class="col-md-6">
      <span class="px-8" style="font-weight: 600;color: #009EF7;">Subject</span>
      <el-select
          class="form-select-solid w-100 px-5"
          placeholder="Select subject"
          v-model="selectedSubject"
          @change="updateAssessment"
      >
        <el-option 
          v-for="subject in subjects" 
          :label="subject.label" 
          :value="subject.subjectId"
          :key="subject">
        </el-option>
      </el-select>
    </div>
  </div>
  <div class="d-flex flex-stack" style="padding-bottom:10px;">
    <div class="col-md-5" align="left">
      <button class="btn btn-sm btn-light" 
        style="border: 1px solid #E4E6EF; margin-right:5px;"
        data-bs-toggle="modal"
        data-bs-target="#modal_edit_type_weight">
          Edit type weight
      </button>
      <button class="btn btn-sm btn-light" 
        style="border: 1px solid #E4E6EF"
        id="create_default_assessments"
        @click="createDefaultAssessments()">
          Create Default Assessments
      </button>
    </div>
    <div class="col-md-7" align="right">
        <el-input
            v-model="search.searchValue"
            placeholder="Type to search"
            class="col-md-8"
            style="padding-right:10px;"/>
            <router-link v-bind:to="'/academics/assessment/0'">
                <button class="btn btn-sm btn-primary">
                    Create new assessment
                </button>
            </router-link>
      </div>
  </div>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="assessment_list">
    <el-table :data="pagedTableDate" style="width: 100%" @row-click="openAssessmentDetail">>
        <el-table-column prop="type" label="Type">
          <template v-slot="scope">
                <div style="display: inline-block;" >
                    <label> {{scope.row.type.name}} </label>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="skill_area" label="Skill Area"></el-table-column>
        <el-table-column prop="title" label="Title"></el-table-column>
        <el-table-column prop="" label="# of Students">
            <template v-slot="scope">
                <div style="display: inline-block;" >
                    <label> {{scope.row.student_grades.length}}</label>
                </div>
            </template>
        </el-table-column>   
        <el-table-column prop="" label="Avg Score">
            <template v-slot="scope">
                <div style="display: inline-block;" >
                    <label v-if="scope.row.student_grades.length > 0"> {{(scope.row.student_grades.map(item => item.achieved_point).reduce((prev, curr) => prev + curr, 0)/scope.row.student_grades.length).toFixed(2)}} </label>
                </div>
            </template>
        </el-table-column>  
    </el-table>
    <div align="right">
      <el-pagination layout="prev, pager, next" v-if="assessments.value" v-model:page-size="pageSize" :total="assessments.value.length" @current-change="setPage"></el-pagination>
    </div>
  </div>
  <EditTypeWeight />
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, reactive, onBeforeMount, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import router from "@/router";
import EditTypeWeight from "@/views/academics/modals/EditTypeWeightModal.vue";
import Swal from "sweetalert2";
import store from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "assessment-list",
  components: {
    EditTypeWeight
  },
  setup() {
    const route = useRoute();
    const subjectId = +route.params.subject_id; 
    const assessments : any= reactive([]);
    const semesters = ref([] as any);
    const subjects = ref([] as any);
    const selectedSemester = ref(null as any);
    const selectedSubject = ref(null as any);
    const pageSize = 10;
    const assessmentTypes = ref([] as any);
    const user = store.getters.currentUser;
    const subjectsAll = ref([] as any);
    let page = reactive({pageValue : 1});

    const pagedTableDate = computed(()=>{
      if(!assessments.value){
        return;
      }

      return assessments.value.filter(data => !search 
                                || data.title.toLowerCase().includes(search.searchValue.toLowerCase())
                                || data.type.name.toLowerCase().includes(search.searchValue.toLowerCase()))
                            .slice(pageSize * page.pageValue - pageSize, pageSize * page.pageValue);
     })

    const search = reactive({searchValue : ''});

    const setPage = (val) => {
      page.pageValue = val;
    }

    const getSemesters = () : any => {
        return new Promise((resolve, reject) => {  
            ApiService.setHeader();
            ApiService.get("class/semesters")
                .then(({ data }) => {
                    let sortedData = data.data.sort(function(a,b) { 
                          if(a.id == b.id) return 0;

                          if (Date.parse(a.start_date!) < Date.parse(b.start_date!))
                              return 1;
                          if (Date.parse(a.start_date!) > Date.parse(b.start_date!))
                              return -1;
                          return 0;
                      });
                    semesters.value = sortedData;
                    resolve(sortedData);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const getClasses = async() => {
        subjects.value = [];
        subjectsAll.value.filter(subject => subject.session.level.program.semester.id == selectedSemester.value).forEach(subject =>{
          subjects.value.push({subjectId: subject.id, label: subject.session.level.program.code+'/'+subject.session.level.code+'/'+subject.session.code+'/'+subject.name})
        });

        if(subjects.value.length > 0){
          selectedSubject.value = subjects.value[0].subjectId;
        }

        getAssessments();
    }

    const getAssessments = () : any => {
        return new Promise((resolve, reject) => { 
            ApiService.setHeader();           
            ApiService.get("assessment/subject/"+selectedSubject.value)
                .then(({ data }) => {
                    assessments.value = data.data;
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }

    const updateClasses = async() => {
      await getClasses();
    }

    const updateAssessment = async() => {
      router.push({ name: "assessments", params: { subject_id: selectedSubject.value } });
      getAssessments();
    }

    const openAssessmentDetail= (row) =>{
      router.push({ name: "assessment-detail", params: { id: row.id } });
    }

    const getSkillAreas = () => {
        return new Promise((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.get("class/subject/"+selectedSubject.value)
                .then(({ data }) => {
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(new Error ('getSemesters error => '+error));
                });
        });
    }
    
    const getAssessmentTypes = async() =>{
        await new Promise((resolve, reject) => {  
            ApiService.setHeader();          
            ApiService.get("assessment/types")
                .then(({ data }) => {
                    assessmentTypes.value = data.data;
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getAssessmentTypes => '+response.data.errors));
                });
        });
    }

    const createDefaultAssessments = () =>{
        getSkillAreas().then((x :any) => {
          var assessmentDetails = [] as any;
          var skillAreas = x.skill_area.split(';');
          skillAreas.forEach((skillArea) => {
            assessmentTypes.value.forEach((type) =>{
              assessmentDetails.push({
                  subject_id:selectedSubject.value,
                  type_id:type.id,
                  skill_area: skillArea,
                  title:type.name,
                  user_id:user.id,
                  max_point: 10
                })
            })
          })
          
          ApiService.setHeader();
          ApiService.post("assessment/bulk", assessmentDetails)
            .then(() => {
                Swal.fire({
                text: "Default assessments have been successfully created!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(() => {
                    getAssessments();
                });
            })
            .catch(({ response }) => {
                Swal.fire({
                text: "Failed to create the new assessments for the subject",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                })
            })
            
        })
    }

    const getSubjects = async() => {
        subjects.value = [];
        await new Promise<void>((resolve, reject) => {   
            ApiService.setHeader(); 
            ApiService.get("class/subjects/detail")
                .then(async({ data }) => {
                  subjectsAll.value = data.data;
                  let availableSemester = data.data.map(subject => subject.session.level.program.semester.id).filter((v, i, a) => a.indexOf(v) === i);
                  semesters.value = semesters.value.filter(x=> availableSemester.includes(x.id));               
                  if(subjectId != 0){
                    selectedSemester.value = data.data.filter(subject => subject.id == subjectId)[0].session.level.program.semester.id;
                  }

                  data.data.filter(subject => subject.session.level.program.semester.id == selectedSemester.value)
                          .sort(function(a,b) { 
                              if (a.code > b.code)
                                  return 1;
                              if (a.code < b.code)
                                  return -1;
                              return 0;
                          })
                          .forEach(subject =>{
                            subjects.value.push({subjectId: subject.id, label: subject.session.level.program.code+'/'+subject.session.level.code+'/'+subject.session.code+'/'+subject.name})
                          });

                  if(subjectId == 0){
                    selectedSubject.value = subjects.value[0].subjectId;
                    router.push({ name: "assessments", params: { subject_id: subjects.value[0].subjectId } });
                  }
                  else{
                    selectedSubject.value = subjectId;                    
                  }

                  await getAssessments();
                  resolve();
                })
                .catch((error) => {
                    reject(new Error ('getClasses error => '+error));
                });
        });
    }

    onBeforeMount(() =>{
      getSemesters().then(async (data) => {
        if(data){
          if(subjectId == 0){
            let today = new Date();
            let currentSemester = data.filter(semester => (semester.start_date <= today && semester.end_date >= today));

            if(currentSemester.length > 0){
              selectedSemester.value = currentSemester[0].id;
            }
            else{
              selectedSemester.value = data[0].id;
            }
          }
          await getSubjects();
          await getAssessmentTypes();
        }
      });      
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Assessment & Grading", ["Academics"]);
    });

    return {
      setPage,
      pagedTableDate,
      pageSize,
      search,
      assessments,
      moment,
      semesters,
      selectedSemester,
      selectedSubject,
      subjects,
      updateAssessment,
      updateClasses,
      openAssessmentDetail,
      createDefaultAssessments
    };
  },
});
</script>

<style scoped>
  .el-table__row{
    cursor:pointer;
  }
</style>

