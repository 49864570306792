
import { defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "edit-class-modal",
  components: {
  },
  setup() {
    const assessmentTypes = ref([] as any);
    const getAssessmentTypes = async() =>{
        await new Promise((resolve, reject) => {  
            ApiService.setHeader();          
            ApiService.get("assessment/types")
                .then(({ data }) => {
                    assessmentTypes.value = data.data;
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getAssessmentTypes => '+response.data.errors));
                });
        });
    }

    const saveData = () =>{
        if(assessmentTypes.value.map(x=>x.percentage).reduce((prev, curr) => prev + curr, 0) != 100){
            Swal.fire({
              text: "Total sum of the percentages should be 100.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
        }
        else{
            ApiService.setHeader();
            ApiService.post("assessment/type", assessmentTypes.value as any)
            .then(() => {
                Swal.fire({
                    text: "Updated successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    }).then(() => {
                        let editCloseButton = document.getElementById('edit_close_button');
                        editCloseButton?.click();
                    });
                
            })
            .catch(({ response }) => {
                Swal.fire({
                    text: "Update failed",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                console.log('handleEdit error => '+response.data.errors);
            });
        }
    }
    
    onMounted (() =>{
        getAssessmentTypes();
    });

    return {
        assessmentTypes,
        saveData,
        getAssessmentTypes
    };
  },
});
