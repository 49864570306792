<template>
  <div
    class="modal fade"
    id="modal_edit_type_weight"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-500px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Edit type weight</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            id="edit_close_button"
            @click="getAssessmentTypes"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
            <div class="flex-row-fluid px-lg-15">
                *it will affect transcript result
              <el-form
                id="kt_modal_edit_class_form"
                class="form"
                :model="formdata"
                ref="formRef"
              >
                  <div class="w-100">
                    <div class="row mb-10">
                      <div class="m-2" v-for="type in assessmentTypes" :key="type.id">
                            <label class="align-items-center fs-5 fw-bold mb-2 col-md-8">
                                {{type.name}}
                            </label>
                        <div class="col-md-4" style="display:inline-block;">
                          <input
                            type="number"
                            style="width:90%;display:inline-block;text-align:right;"
                            class="form-control form-control-md form-control-solid"
                            name="type.percentage"
                            v-model="type.percentage"
                          />%
                        </div>
                      </div>
                  </div>
                </div>
              <div class="d-flex flex-stack" style="float:right">
                <div>
                  <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click="saveData()"
                    >
                    <span class="indicator-label">
                        Save
                    </span>
                  </button>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "edit-class-modal",
  components: {
  },
  setup() {
    const assessmentTypes = ref([] as any);
    const getAssessmentTypes = async() =>{
        await new Promise((resolve, reject) => {  
            ApiService.setHeader();          
            ApiService.get("assessment/types")
                .then(({ data }) => {
                    assessmentTypes.value = data.data;
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getAssessmentTypes => '+response.data.errors));
                });
        });
    }

    const saveData = () =>{
        if(assessmentTypes.value.map(x=>x.percentage).reduce((prev, curr) => prev + curr, 0) != 100){
            Swal.fire({
              text: "Total sum of the percentages should be 100.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
        }
        else{
            ApiService.setHeader();
            ApiService.post("assessment/type", assessmentTypes.value as any)
            .then(() => {
                Swal.fire({
                    text: "Updated successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    }).then(() => {
                        let editCloseButton = document.getElementById('edit_close_button');
                        editCloseButton?.click();
                    });
                
            })
            .catch(({ response }) => {
                Swal.fire({
                    text: "Update failed",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                console.log('handleEdit error => '+response.data.errors);
            });
        }
    }
    
    onMounted (() =>{
        getAssessmentTypes();
    });

    return {
        assessmentTypes,
        saveData,
        getAssessmentTypes
    };
  },
});
</script>
